import {apiCall} from '../../middlewares';
import {ENDPOINTS} from '../../config/constants';

const createCryptoAccount = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.crypto.create_account,
    headers,
    data,
  });
};

const getNetworks = (headers, params) => {
  return apiCall({
    ...ENDPOINTS.crypto.get_networks(params),
    headers,
  })
}

const lostFundsAlert = (headers, params) => {
  return apiCall({
    ...ENDPOINTS.crypto.lost_funds,
    params,
    headers,
  })
}

const getRiskwallet = (headers, data) => {
  return apiCall({
    ...ENDPOINTS.crypto.get_riskwallet,
    headers,
    data,
  })
};

export default {
  createCryptoAccount,
  getNetworks,
  lostFundsAlert,
  getRiskwallet,
};
