import React, { createContext, useCallback, useContext, useMemo, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';

import config from '../config';

import { RULES_KEY, RULES_DEFAULT_VALUE } from './state';
import transactionsService from '../services/transactions';
import useResidenceConfig from '../hooks/useResidenceConfig';

const rules = (useStorage) => {
  const RulesContext = createContext({
    rules: RULES_DEFAULT_VALUE,
    updateRules: () => {},
    rulesIsReady: false,
  });
  const { Provider } = RulesContext;

  let _isRulesFetch = false;
  let _isReLoadRules = false;

  const RulesProvider = (props) => {
    const [rules, updateRules, hydrated] = useStorage(
      RULES_KEY,
      RULES_DEFAULT_VALUE,
    );

    const value = useMemo(
      () => ({
        rules,
        updateRules,
        rulesIsReady: hydrated,
      }),
      [rules, updateRules, hydrated],
    );

    return <Provider value={value} {...props} />;
  };

  RulesProvider.propTypes = {
    children: PropTypes.shape({}).isRequired,
  };

  const useRules = () => {
    const context = useContext(RulesContext);
    const residence = useResidenceConfig();
    const {
      iso_code,
      isAddInternationalFavorites,
      isUSDT,
      isBTC,
      isUSDC,
    } = residence;
    const { useUser } = config.getInstance().getConfiguration();
    const { user } = useUser();
    const {
      balances,
      active_cryptos,
      is_business,
      headers,
      allow_third_party_international_transactions
    } = user || {};

    if (!context) {
      throw new Error('useRules must be used within a RulesContext');
    }

    const { rules, updateRules: update, rulesIsReady, ...rest } = context;

    const vitaCountries = useMemo(
      () => {
        const { transferRules } = rules;

        if (transferRules.hasOwnProperty('rules')) {

          const rules_aux = { ...transferRules.rules };

          delete rules_aux['headers'];
          delete rules_aux['btc'];

          if (!(isAddInternationalFavorites || allow_third_party_international_transactions)) {
            delete rules_aux[iso_code.toLowerCase()];
          }

          let keys = Object.keys(rules_aux).filter(key => {
            return rules_aux[key].is_transfer === true;
          });

          let countries = keys.map(key => {
            const aux = { ...rules_aux[key] };
            delete aux['rules'];
            return aux;
          });

          return countries;
        } else {
          return [];
        }
      },
      [rules, iso_code, isAddInternationalFavorites]
    );

    const cryptoExistent = useMemo(() => {
      const { transferRules } = rules;

      if (transferRules.hasOwnProperty('rules')) {
        const rules_aux = { ...transferRules.rules }

        const cryptos = Object.keys(rules_aux).filter(key => {
          return !!rules_aux[key].is_crypto;
        });

        return cryptos.map(currency => currency.toUpperCase());
      } else {
        return [];
      }
    }, [
      rules,
    ]);

    const cryptoCurrencies = useMemo(() => {
      const { transferRules } = rules;

      if (transferRules.hasOwnProperty('rules')) {

        const rules_aux = { ...transferRules.rules }

        const cryptoCurrenciesAvailable = Object.keys(rules_aux).filter(key => {
          let available = !!rules_aux[key].is_crypto;

          if (rules_aux[key].currency_code === 'USDT') available = isUSDT && active_cryptos?.usdt && !is_business;
          if (rules_aux[key].currency_code === 'USDC') available = isUSDC && active_cryptos?.usdc && !is_business;
          if (rules_aux[key].currency_code === 'BTC') available = isBTC && !is_business;

          return available;
        });

        return cryptoCurrenciesAvailable.map(currency => currency.toUpperCase());
      } else {
        return [];
      }
    }, [
      active_cryptos,
      is_business,
      rules,
      isUSDT,
      isUSDC,
      isBTC,
    ]);

    const fiatCurrencies = useMemo(() => {
      let fiat = [];

      if (balances) {
        fiat = Object.keys(balances).filter(v => {
          return !!!cryptoExistent.includes(v.toUpperCase());
        });
      } 

      return fiat;
    }, [balances, cryptoExistent]);

    const fiatAndCryptoCurrencies = useMemo(() => {
      return [...fiatCurrencies, ...cryptoCurrencies];
    }, [
      cryptoCurrencies,
      fiatCurrencies,
    ]);

    const cryptoAvailable = useMemo(() => {
      if (cryptoExistent.length > 0) {
        const cryptos = cryptoExistent.filter(crypto => {
          return !!residence[`is_${crypto.toLowerCase()}`];
        });

        return cryptos;
      } else {
        return [];
      }
    }, [cryptoExistent]);

    const setTransferRules = useCallback(async user => {
      if (!_isRulesFetch) {
        _isRulesFetch = true;

        try {
          const response = await transactionsService.getTransferRules(
            headers,
          );
          delete response.headers;

          update({
            transferRules: {
              expiry: moment(),
              rules: response,
            },
          });

          _isRulesFetch = false;
        } catch (error) {
          _isRulesFetch = false;
        }
      }
    }, [update]);

    const cleanRules = useCallback(() => {
      update(RULES_DEFAULT_VALUE);
    }, [update]);

    useEffect(() => {
      (async () => {
        if (!_isReLoadRules && rules.transferRules.rules.length === 0 && user) {
          _isReLoadRules = true;
          await setTransferRules(user);
          _isReLoadRules = false;
        }
      })();

      return () => {
        _isReLoadRules = false;
      }
    }, []);

    return {
      ...rest,
      rules: rules || RULES_DEFAULT_VALUE,
      vitaCountries,
      cryptoCurrencies,
      fiatCurrencies,
      cryptoExistent,
      cryptoAvailable,
      fiatAndCryptoCurrencies,
      setTransferRules,
      cleanRules,
    };
  };

  return {
    RulesProvider,
    useRules,
  };
};

export default rules;

